<template>
  <div>
    <Modal
      v-model="showOrderingModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      title="批量导出"
    >
      <Form
        class="form"
        ref="bulkExportForm"
        :model="bulkExportData"
        :rules="ruleValidate"
        label-width="70"
        :label-position="labelPosition"
      >
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="产品：" prop="bulk_export_product_id">
              <Select
                v-model="bulkExportData.bulk_export_product_id"
                @on-select="productSelect"
              >
                <Option
                  v-for="(item, index) in bulkExportParams"
                  :value="item.id"
                  :key="index"
                  :label="item.store_name"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="下单时间" prop="bulk_export_order_created_at_hour">
              <Select
                v-model="bulkExportData.bulk_export_order_created_at_hour"
                @on-select="orderTimeSelect"
              >
                <Option
                  v-for="(time, ix) in buyTimeList"
                  :value="time.time"
                  :key="ix"
                  :label="time.time"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <!-- <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="选择证书" prop="bulk_export_certs">
              <Select v-model="bulkExportData.bulk_export_certs" multiple>
                <Option
                  v-for="cert in certList"
                  :value="cert.id"
                  :key="cert.id"
                  :label="cert.common_name"
                >
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="导文件名" prop="bulk_export_fullname">
              <Checkbox v-model="bulk_export_fullname"
                >格式 “产品名字(下单时间) × 本数.zip”
              </Checkbox>
            </FormItem>
          </Col>
        </Row> -->
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button
            type="primary"
            size="large"
            :loading="applyLoading"
            @click="submitForm('bulkExportForm')"
            >导出</Button
          >
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, getCurrentInstance } from "vue";
import { Message } from "view-ui-plus";
import { userProductParamsApi, bulkExportApi } from "@/api/personalCenter";
const { proxy } = getCurrentInstance(); // 获取组件实例
let companyList = reactive([]);
let showOrderingModal = ref(false);
let bulkExportData = reactive({
  bulk_export_product_id: "",
  bulk_export_order_created_at_hour: "",
  bulk_export_certs: "",
  bulk_export_fullname: "",
});
let applyLoading = ref(false);
let bulk_export_fullname = ref(false);
let buyTimeList = reactive([]);
let certList = reactive([]);
let bulkExportParams = reactive([]);
const userProductParams = () => {
  userProductParamsApi().then((res) => {
    if (res.status == 200) {
      if(res.data && res.data.length>0){
        bulkExportParams.length = 0; // 清空数组
        bulkExportParams.push(...res.data);
      }
    }
  });
};
const productSelect = (e) => {
  if (bulkExportParams.length > 0)
    bulkExportParams.forEach((item) => {
      if (item.id == e.value) {
        buyTimeList = item.bulk_time;
      }
    });
};
const orderTimeSelect = (e) => {
  if(buyTimeList.length>0){
    buyTimeList.forEach((item) => {
      if (item.time == e.value) {
        certList = item.domains;
      }
    });
  }
};
const submitForm = () => {
  proxy.$refs.bulkExportForm.validate((valid) => {
    if (valid) {
      bulkExportData.bulk_export_fullname =
        bulk_export_fullname.value == true ? "on" : "";
      console.log(bulkExportData);
      bulkExportApi(bulkExportData)
        .then((res) => {
          if (res.status == 200) {
            window.location.href = `https://api.rjxssl.com/api/download?path=${res.data.zip_path}`;
            Message.success("导出成功");
            showOrderingModal.value = false;
          }
        })
        .catch((res) => {
          Message.error({
            content: res.msg,
            duration: 3,
          });
          applyLoading = false;
        });
    } else {
      Message.error(res.msg);
    }
  });
};
const openModal = () => {
  showOrderingModal.value = true;
  userProductParams();
};
const cancel = () => {
  showOrderingModal.value = false;
  applyLoading = false;
};
defineOptions({
  name: "BulkExportModal",
});
defineExpose({
  openModal,
  cancel,
  bulkExportForm: proxy.$refs.bulkExportForm,
});
</script>
<style lang="less" scoped></style>
